<mat-sidenav-container
  *ngIf="{
    matomoEnabled: matomoEnabled(),
    currentUser: currentUser$ | async,
    channelCanPublish: channelCanPublish$ | async,
    channelCanModerate: channelCanModerate$ | async,
    campaignSummary: campaignSummary$ | async,
    commentSummary: commentSummary$ | async,
    shouldBeOver: shouldBeOver$ | async,
    profileImage: profileImage$ | async
  } as data"
  id="wrapper"
  [ngClass]="{ 'logging-in': !isAuthenticated() }"
>
  @if (isAuthenticated() && !isPartialLogin()) {
    <mat-sidenav
      #sideNav
      [fixedTopGap]="!data.shouldBeOver ? 56 : 0"
      position="start"
      [fixedInViewport]="true"
      [opened]="!data.shouldBeOver"
      [mode]="data.shouldBeOver ? 'over' : 'side'"
      [disableClose]="!data.shouldBeOver"
      mode="over"
      id="sidebar-wrapper"
    >
      <div class="sidebar-nav-wrapper fx-layout-column">
        @if (configSignal(); as config) {
          <!-- Profile -->
          <div class="sidebar-nav-profile" [ngClass]="{ 'in-disguise': config.impersonatorEmail }">
            <a
              routerLink="/impersonate"
              [queryParams]="{ campaignId: config.campaignId }"
              id="btnImpersonate"
              *ngIf="config.impersonatorEmail"
            >
              <mat-icon svgIcon="incognito" title="Much disguise. So sneak. Wow."></mat-icon>
            </a>
            <div class="fx-layout-row">
              <span class="sidebar-nav-image-wrapper">
                <ih-image [imageInfo]="data.profileImage"></ih-image>
              </span>
              <span class="sidebar-nav-info-wrapper">
                <span class="sidebar-nav-name" [title]="data.currentUser.first_name + ' ' + data.currentUser.last_name">
                  {{ data.currentUser.first_name + ' ' + data.currentUser.last_name }}
                </span>
                <span class="sidebar-nav-campaign-name" [attr.title]="config.businessName">{{
                  config.businessName
                }}</span>
              </span>
            </div>
            <!-- Upgrade subscription -->
            <a
              class="sidebar-nav-upgrade-subscription-button"
              mat-button
              trackClick="Builder upgrade subscription clicked"
              *ngIf="showUpgrade$ | async"
              routerLink="/upgrade"
            >
              <span class="sidebar-nav-upgrade-subscription-text">Upgrade subscription</span>
            </a>
          </div>
        }

        <!-- Nav -->
        <nav class="left-navigation fx-flex">
          <div class="sidenav-item">
            <a href="" (click)="showUpdateDialog($event)" *ngIf="updateAvailable$ | async">
              <mat-icon svgIcon="update"></mat-icon>
              <span class="sidenav-item-text">Update available</span>
            </a>
          </div>
          <div class="sidenav-item sub-menu">
            <a href="" (click)="toggleParent($event)">
              <mat-icon>
                <svg id="account-box" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M6,17C6,15 10,13.9 12,13.9C14,13.9 18,15 18,17V18H6M15,9A3,3 0 0,1 12,12A3,3 0 0,1 9,9A3,3 0 0,1 12,6A3,3 0 0,1 15,9M3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5C3.89,3 3,3.9 3,5Z"
                  />
                </svg>
              </mat-icon>
              <span class="sidenav-item-text">My profile</span>

              <b class="caret"></b>
            </a>
            <ul class="sub">
              <!--Edit profile-->
              <div class="sidenav-item">
                <a
                  class="profile-nav-list-item nav-text-color"
                  routerLink="/account/profile"
                  routerLinkActive="active"
                  matRipple
                  trackClick="Edit profile clicked"
                  >Edit profile</a
                >
              </div>
              <!--Notification settings-->
              <div class="sidenav-item">
                <a
                  class="profile-nav-list-item nav-text-color"
                  routerLink="/account/notifications"
                  routerLinkActive="active"
                  matRipple
                  trackClick="Notification settings clicked"
                  >Notification settings</a
                >
              </div>
              <!--Security settings-->
              <div class="sidenav-item">
                <a
                  class="profile-nav-list-item nav-text-color"
                  routerLink="/account/security"
                  routerLinkActive="active"
                  matRipple
                  trackClick="Security settings clicked"
                  >Security settings</a
                >
              </div>
            </ul>
          </div>
          <div class="sidenav-item">
            <a routerLink="/dashboard" routerLinkActive="active">
              <mat-icon>
                <svg id="view-dashboard" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z" />
                </svg>
              </mat-icon>
              <span class="sidenav-item-text">Dashboard</span>
            </a>
          </div>
          <div class="sidenav-item">
            <a routerLink="/communicate/messages" routerLinkActive="active">
              <mat-icon svgIcon="email"></mat-icon>
              <span class="sidenav-item-text">Message center</span
              ><span class="badge accent-background-color" *ngIf="(totalMessageCount$ | async) > 0">{{
                totalMessageCount$ | async
              }}</span>
            </a>
          </div>
          <div class="sidenav-item sub-menu" routerLinkActive="open active">
            <a href="" (click)="toggleParent($event)">
              <mat-icon>
                <svg id="plus-circle" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                </svg>
              </mat-icon>
              <span class="sidenav-item-text">Create</span>

              <b class="caret"></b>
            </a>
            <ul class="sub" *ngIf="menuOptions$ | async as menuOptions">
              <div class="sidenav-item">
                <a
                  routerLink="/communicate"
                  [queryParams]="{ selectedTab: 'all' }"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <mat-icon svgIcon="earth"></mat-icon>
                  <span class="sidenav-item-text">All content</span>
                </a>
              </div>
              <div class="sidenav-item">
                <a routerLink="/communicate/posts" [queryParams]="{ selectedTab: 'all' }" routerLinkActive="active">
                  <mat-icon svgIcon="newspaper"></mat-icon>
                  <span class="sidenav-item-text">Posts</span>
                </a>
              </div>
              <div class="sidenav-item">
                <a routerLink="/communicate/events" [queryParams]="{ selectedTab: 'all' }" routerLinkActive="active"
                  ><mat-icon svgIcon="calendar-today"></mat-icon>
                  <span class="sidenav-item-text">Events</span>
                </a>
              </div>
              <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManageGive">
                <a routerLink="/communicate/give" routerLinkActive="active"
                  ><mat-icon svgIcon="heart"></mat-icon>
                  <span class="sidenav-item-text">Give</span>
                </a>
              </div>
              <div class="sidenav-item">
                <a
                  *ngIf="menuOptions.pagesEnabled"
                  routerLink="/communicate/pages"
                  [queryParams]="{ selectedTab: 'all' }"
                  routerLinkActive="active"
                >
                  <mat-icon>
                    <svg id="file-document" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z"
                      />
                    </svg>
                  </mat-icon>
                  <span class="sidenav-item-text">Pages</span>
                </a>
              </div>
              <div class="sidenav-item">
                <a
                  *ngIf="menuOptions.slidersEnabled"
                  routerLink="/communicate/sliders"
                  [queryParams]="{ selectedTab: 'all' }"
                  routerLinkActive="active"
                >
                  <mat-icon svgIcon="image"></mat-icon>
                  <span class="sidenav-item-text">Sliders</span>
                </a>
              </div>
            </ul>
          </div>
          <div
            class="sidenav-item sub-menu"
            *ngIf="data.channelCanPublish || data.channelCanModerate"
            routerLinkActive="open active"
          >
            <ng-container *ngIf="commentSummary$ | async as commentSummary">
              <a href="" (click)="toggleParent($event)">
                <mat-icon>
                  <svg id="comment-check" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,15L18,7L16.59,5.58L10,12.17L7.41,9.59L6,11L10,15Z"
                    />
                  </svg>
                </mat-icon>
                <span class="sidenav-item-text">Moderate</span>
                <span
                  class="badge accent-background-color"
                  *ngIf="(commentSummary.Pending ?? 0) + (data.campaignSummary?.unpublishedUGC ?? 0) > 0"
                >
                  {{ (commentSummary.Pending ?? 0) + (data.campaignSummary?.unpublishedUGC ?? 0) }}
                </span>

                <b class="caret"></b>
              </a>
              <ul class="sub">
                <div class="sidenav-item" *ngIf="data.channelCanModerate">
                  <a routerLink="/communicate/comments" routerLinkActive="active">
                    <mat-icon>
                      <svg id="comment-check" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,15L18,7L16.59,5.58L10,12.17L7.41,9.59L6,11L10,15Z"
                        />
                      </svg>
                    </mat-icon>
                    <span class="sidenav-item-text">Comments</span>
                    <span class="badge accent-background-color" *ngIf="commentSummary.Pending > 0">{{
                      commentSummary.Pending
                    }}</span>
                  </a>
                </div>
                <div class="sidenav-item" *ngIf="data.channelCanPublish">
                  <a
                    routerLink="/communicate/submittedContent"
                    [queryParams]="{ selectedTab: 'unpublished' }"
                    routerLinkActive="active"
                  >
                    <mat-icon>
                      <svg id="account-edit" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.09 20.21,11.09 20.42,11.3L21.7,12.58C21.91,12.79 21.91,13.14 21.7,13.35M12,18.94L18.06,12.88L20.11,14.93L14.06,21H12V18.94M12,14C7.58,14 4,15.79 4,18V20H10V18.11L14,14.11C13.34,14.03 12.67,14 12,14M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4Z"
                        />
                      </svg>
                    </mat-icon>
                    <span class="sidenav-item-text">Submitted content</span>
                    <span class="badge accent-background-color" *ngIf="data.campaignSummary?.unpublishedUGC > 0">{{
                      data.campaignSummary.unpublishedUGC
                    }}</span>
                  </a>
                </div>
              </ul>
            </ng-container>
          </div>

          <div class="sidenav-item sub-menu" routerLinkActive="open active">
            <a href="" (click)="toggleParent($event)">
              <mat-icon>
                <svg id="account-group" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
                  />
                </svg>
              </mat-icon>
              <span class="sidenav-item-text">Channels & members</span>
              <span class="badge accent-background-color" *ngIf="data.campaignSummary?.pendingRequests > 0">
                {{ data.campaignSummary?.pendingRequests }}
              </span>

              <b class="caret"></b>
            </a>
            <ul class="sub">
              <div class="sidenav-item">
                <a routerLink="/manage/channels" routerLinkActive="active">
                  <mat-icon svgIcon="pound"></mat-icon>Channels<span
                    class="badge accent-background-color"
                    *ngIf="data.campaignSummary?.pendingRequests > 0"
                    >{{ data.campaignSummary?.pendingRequests }}</span
                  >
                </a>
              </div>
              <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.UsersInviteEdit">
                <a routerLink="/manage/users" routerLinkActive="active">
                  <mat-icon>
                    <svg id="account-multiple" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z"
                      />
                    </svg>
                  </mat-icon>
                  <span class="sidenav-item-text">Members</span>
                </a>
              </div>
              <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManagePermissions">
                <a routerLink="/manage/roles" routerLinkActive="active"
                  ><mat-icon svgIcon="key"></mat-icon>Builder member roles
                </a>
              </div>
              <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManageUserTags">
                <a routerLink="/manage/userTags" routerLinkActive="active">
                  <mat-icon>
                    <svg id="tag" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z"
                      />
                    </svg>
                  </mat-icon>
                  <span class="sidenav-item-text">Member profile sections</span>
                </a>
              </div>
            </ul>
          </div>

          <div
            class="sidenav-item sub-menu"
            *ngIf="
              data.currentUser.roleSecurity.permissions.ViewAnalytics ||
              data.currentUser.roleSecurity.permissions.ViewPaymentsSubscriptions ||
              data.currentUser.roleSecurity.permissions.ViewDonations
            "
            routerLinkActive="open active"
          >
            <a href="" id="navView" (click)="toggleParent($event)">
              <mat-icon>
                <svg id="file-chart" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z"
                  />
                </svg>
              </mat-icon>
              <span class="sidenav-item-text">Reports</span>
              <b class="caret"></b>
            </a>
            <ul class="sub">
              <div class="sidenav-item" *ngIf="data.matomoEnabled">
                <a routerLink="/view/reports" routerLinkActive="active">
                  <mat-icon>
                    <svg id="chart-line" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z"
                      />
                    </svg>
                  </mat-icon>
                  <span class="sidenav-item-text">Analytics</span>
                </a>
              </div>
              <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ViewPaymentsSubscriptions">
                <a routerLink="/view/payments" routerLinkActive="active">
                  <mat-icon>
                    <svg id="credit-card" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z"
                      />
                    </svg>
                  </mat-icon>
                  <span class="sidenav-item-text">Payments</span>
                </a>
              </div>
              @if (paidChannelsEnabled()) {
                <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ViewPaymentsSubscriptions">
                  <a routerLink="/manage/settings/products" routerLinkActive="active">
                    <mat-icon class="item-icon" svgIcon="cart-outline"></mat-icon>
                    <span class="sidenav-item-text">Products</span>
                  </a>
                </div>
              }
              <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ViewPaymentsSubscriptions">
                <a routerLink="/view/subscriptions" routerLinkActive="active">
                  <mat-icon>
                    <svg id="autorenew" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12,6V9L16,5L12,1V4A8,8 0 0,0 4,12C4,13.57 4.46,15.03 5.24,16.26L6.7,14.8C6.25,13.97 6,13 6,12A6,6 0 0,1 12,6M18.76,7.74L17.3,9.2C17.74,10.04 18,11 18,12A6,6 0 0,1 12,18V15L8,19L12,23V20A8,8 0 0,0 20,12C20,10.43 19.54,8.97 18.76,7.74Z"
                      />
                    </svg>
                  </mat-icon>
                  <span class="sidenav-item-text">Subscriptions</span>
                </a>
              </div>
            </ul>
          </div>

          <div
            class="sidenav-item sub-menu"
            *ngIf="
              data.currentUser.roleSecurity.permissions.ManageAppSettings ||
              data.currentUser.roleSecurity.permissions.ManageBilling ||
              data.currentUser.roleSecurity.permissions.ManageContact ||
              data.currentUser.roleSecurity.permissions.ManageIntegrations ||
              data.currentUser.roleSecurity.permissions.ManageMerchantAccounts ||
              data.currentUser.roleSecurity.permissions.ManagePostTypes
            "
            routerLinkActive="open active"
          >
            <ng-container *ngIf="menuOptions$ | async as menuOptions">
              <a href="" (click)="toggleParent($event)" *ngIf="showConfigure">
                <mat-icon>
                  <svg id="cog" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
                    />
                  </svg>
                </mat-icon>
                <span class="sidenav-item-text">Configure</span>
                <b class="caret"></b>
              </a>
              <ul class="sub">
                <!-- ADVANCED OPTIONS -->
                <div class="sidenav-item" *ngIf="menuOptions.advancedOptionsEnabled">
                  <a routerLink="/manage/settings/advancedOptions" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
                        />
                      </svg>
                    </mat-icon>
                    Advanced options
                  </a>
                </div>

                <!-- CUSTOM CODE -->
                <div class="sidenav-item" *ngIf="menuOptions.customCodeEnabled">
                  <a routerLink="/manage/settings/customCode" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M14.6,16.6L19.2,12L14.6,7.4L16,6L22,12L16,18L14.6,16.6M9.4,16.6L4.8,12L9.4,7.4L8,6L2,12L8,18L9.4,16.6Z"
                        />
                      </svg>
                    </mat-icon>
                    Custom code
                  </a>
                </div>

                <!-- CUSTOM DOMAINS -->
                <div class="sidenav-item" *ngIf="showCustomDomains$ | async">
                  <a routerLink="/manage/settings/customDomains" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19,15V19H5V15H19M20,13H4A1,1 0 0,0 3,14V20A1,1 0 0,0 4,21H20A1,1 0 0,0 21,20V14A1,1 0 0,0 20,13M7,18.5A1.5,1.5 0 0,1 5.5,17A1.5,1.5 0 0,1 7,15.5A1.5,1.5 0 0,1 8.5,17A1.5,1.5 0 0,1 7,18.5M19,5V9H5V5H19M20,3H4A1,1 0 0,0 3,4V10A1,1 0 0,0 4,11H20A1,1 0 0,0 21,10V4A1,1 0 0,0 20,3M7,8.5A1.5,1.5 0 0,1 5.5,7A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 8.5,7A1.5,1.5 0 0,1 7,8.5Z"
                        />
                      </svg>
                    </mat-icon>
                    Custom domains
                  </a>
                </div>
                <!-- HOME CUSTOMIZATION -->
                <div class="sidenav-item" *ngIf="menuOptions.homeCustomizationEnabled">
                  <button (click)="showHomeCustomization()" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z"
                        />
                      </svg>
                    </mat-icon>
                    Home customization
                  </button>
                </div>
                <!-- LOOK AND FEEL -->
                <div class="sidenav-item" *ngIf="menuOptions.lookAndFeelEnabled">
                  <a routerLink="/manage/settings/lookAndFeel" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M17.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,9A1.5,1.5 0 0,1 19,10.5A1.5,1.5 0 0,1 17.5,12M14.5,8A1.5,1.5 0 0,1 13,6.5A1.5,1.5 0 0,1 14.5,5A1.5,1.5 0 0,1 16,6.5A1.5,1.5 0 0,1 14.5,8M9.5,8A1.5,1.5 0 0,1 8,6.5A1.5,1.5 0 0,1 9.5,5A1.5,1.5 0 0,1 11,6.5A1.5,1.5 0 0,1 9.5,8M6.5,12A1.5,1.5 0 0,1 5,10.5A1.5,1.5 0 0,1 6.5,9A1.5,1.5 0 0,1 8,10.5A1.5,1.5 0 0,1 6.5,12M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A1.5,1.5 0 0,0 13.5,19.5C13.5,19.11 13.35,18.76 13.11,18.5C12.88,18.23 12.73,17.88 12.73,17.5A1.5,1.5 0 0,1 14.23,16H16A5,5 0 0,0 21,11C21,6.58 16.97,3 12,3Z"
                        />
                      </svg>
                    </mat-icon>
                    Look and feel
                  </a>
                </div>

                <!-- Menu -->
                <div
                  class="sidenav-item"
                  *ngIf="data.currentUser.roleSecurity.permissions.ManageAppSettings && menuOptions.menuEditEnabled"
                >
                  <a routerLink="/manage/settings/menu" routerLinkActive="active">
                    <mat-icon>
                      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M5,9.5L7.5,14H2.5L5,9.5M3,4H7V8H3V4M5,20A2,2 0 0,0 7,18A2,2 0 0,0 5,16A2,2 0 0,0 3,18A2,2 0 0,0 5,20M9,5V7H21V5H9M9,19H21V17H9V19M9,13H21V11H9V13Z"
                        />
                      </svg>
                    </mat-icon>
                    Menu editor
                  </a>
                </div>

                <!-- Payment processors -->
                <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManageMerchantAccounts">
                  <a routerLink="/manage/settings/merchantAccounts" routerLinkActive="active">
                    <mat-icon svgIcon="credit-card-outline"></mat-icon>
                    Payment processors
                  </a>
                </div>

                @if (configSignal(); as config) {
                  <!-- Post labels -->
                  <div
                    class="sidenav-item"
                    *ngIf="
                      config.currentUser.roleSecurity.permissions.ManageAppSettings && menuOptions.postTypesEnabled
                    "
                  >
                    <a routerLink="/manage/settings/postTypes" routerLinkActive="active">
                      <mat-icon>
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M8,3A2,2 0 0,0 6,5V9A2,2 0 0,1 4,11H3V13H4A2,2 0 0,1 6,15V19A2,2 0 0,0 8,21H10V19H8V14A2,2 0 0,0 6,12A2,2 0 0,0 8,10V5H10V3M16,3A2,2 0 0,1 18,5V9A2,2 0 0,0 20,11H21V13H20A2,2 0 0,0 18,15V19A2,2 0 0,1 16,21H14V19H16V14A2,2 0 0,1 18,12A2,2 0 0,1 16,10V5H14V3H16Z"
                          />
                        </svg>
                      </mat-icon>
                      Post labels
                    </a>
                  </div>
                }

                <!-- URL REDIRECTS -->
                <li *ngIf="menuOptions.urlRedirectsEnabled">
                  <a routerLink="/manage/settings/urlRedirects" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M8,11h8v2H8V11z M20.1,12H22c0-2.76-2.24-5-5-5h-4v1.9h4C18.71,8.9,20.1,10.29,20.1,12z M3.9,12c0-1.71,1.39-3.1,3.1-3.1h4 V7H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h4v-1.9H7C5.29,15.1,3.9,13.71,3.9,12z M19,12h-2v3h-3v2h3v3h2v-3h3v-2h-3V12z"
                        />
                      </svg>
                    </mat-icon>
                    Url redirects
                  </a>
                </li>

                <!-- Welcome Message -->
                <div class="sidenav-item" *ngIf="menuOptions.welcomeMessageEnabled">
                  <a routerLink="/manage/settings/welcomeMessage" routerLinkActive="active">
                    <mat-icon>
                      <svg viewBox="0 0 24 24">
                        <path
                          d="M21,12.13C20.85,12.14 20.71,12.19 20.61,12.3L19.61,13.3L21.66,15.3L22.66,14.3C22.88,14.09 22.88,13.74 22.66,13.53L21.42,12.3C21.32,12.19 21.18,12.14 21.04,12.13M19.04,13.88L13,19.94V22H15.06L21.12,15.93M20,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H11V19.11L19.24,10.89C19.71,10.4 20.36,10.13 21.04,10.13C21.38,10.13 21.72,10.19 22.04,10.32V6C22.04,4.88 21.12,4 20,4M20,8L12,13L4,8V6L12,11L20,6"
                        />
                      </svg>
                    </mat-icon>
                    Welcome message</a
                  >
                </div>

                <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManageBilling">
                  <a href="/api/campaign/billingUrl" [class.disabled]="!(paidPlan$ | async)">
                    <mat-icon>
                      <svg id="credit-card" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z"
                        />
                      </svg>
                    </mat-icon>
                    <span>Billing</span>
                    <span *ngIf="!(paidPlan$ | async)">&nbsp;(Requires a paid plan)</span>
                    <span *ngIf="billingUrlLoading$ | async" class="loading-animation"></span>
                  </a>
                </div>

                <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManageContact">
                  <a routerLink="/manage/settings/contactUs" routerLinkActive="active">
                    <mat-icon>
                      <svg id="email-outline" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z"
                        />
                      </svg>
                    </mat-icon>
                    Contact page
                  </a>
                </div>

                <div class="sidenav-item" *ngIf="data.currentUser.roleSecurity.permissions.ManageIntegrations">
                  <a routerLink="/manage/integrations" routerLinkActive="active">
                    <mat-icon>
                      <svg id="puzzle" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M20.5,11H19V7C19,5.89 18.1,5 17,5H13V3.5A2.5,2.5 0 0,0 10.5,1A2.5,2.5 0 0,0 8,3.5V5H4A2,2 0 0,0 2,7V10.8H3.5C5,10.8 6.2,12 6.2,13.5C6.2,15 5,16.2 3.5,16.2H2V20A2,2 0 0,0 4,22H7.8V20.5C7.8,19 9,17.8 10.5,17.8C12,17.8 13.2,19 13.2,20.5V22H17A2,2 0 0,0 19,20V16H20.5A2.5,2.5 0 0,0 23,13.5A2.5,2.5 0 0,0 20.5,11Z"
                        />
                      </svg>
                    </mat-icon>
                    Integrations
                  </a>
                </div>
              </ul>
            </ng-container>
          </div>

          <div class="sidenav-item">
            <a
              class="profile-nav-list-item nav-text-color"
              href="https://support.ihub.app"
              target="_blank"
              matRipple
              trackClick="Help center clicked"
            >
              <mat-icon>
                <svg id="help-circle" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
                  />
                </svg>
              </mat-icon>
              Help center
            </a>
          </div>
          <div class="sidenav-item">
            <a href="" (click)="showFeedbackDialog($event)">
              <mat-icon>
                <svg id="bullhorn" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                  />
                </svg>
              </mat-icon>
              Give feedback
            </a>
          </div>
          <div class="sidenav-item">
            <a routerLink="/account/logout" routerLinkActive="active">
              <mat-icon>
                <svg id="logout-variant" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
                  />
                </svg>
              </mat-icon>
              Logout
            </a>
          </div>
          @if (configSignal()?.impersonatorEmail || (canSwitchApps$ | async)) {
            <div class="sidenav-item">
              <a routerLink="/apps" routerLinkActive="active">
                <mat-icon>
                  <svg id="swap-horizontal-bold" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M8,10V13H14V18H8V21L2,15.5L8,10M22,8.5L16,3V6H10V11H16V14L22,8.5Z" />
                  </svg>
                </mat-icon>
                Switch apps
              </a>
            </div>
          }
        </nav>

        <div class="sidenav-item footer version-info" (click)="showDeviceInfo()">
          {{ version() }}
        </div>
      </div>
    </mat-sidenav>
  }

  <mat-sidenav-content id="main-content">
    @if (isAuthenticated() || isPartialLogin()) {
      <div class="builder-header fx-layout-row fx-layout-align-start-center">
        @if (isAuthenticated() && !isSwitchingApps() && data.shouldBeOver) {
          <button id="menu-toggle" mat-icon-button (click)="sideNav.toggle()">
            <mat-icon>
              <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"></path>
              </svg>
            </mat-icon>
          </button>
        }
        <a [routerLink]="[isAuthenticated() ? '/dashboard' : '/apps']">
          <img src="https://ih-cdn.ihub.app/img/ihubapp-logo-56px.png" alt="IHUBApp" class="logo" />
        </a>

        <span class="fx-flex"></span>
        @if (isAuthenticated() && !isSwitchingApps()) {
          <!-- My Hub -->
          <a mat-flat-button class="my-hub-button fx-hide-lt-sm" [attr.href]="appUrl" color="primary" target="_blank">
            <ng-container *ngTemplateOutlet="myHub"></ng-container>
          </a>

          <ng-container *ngIf="notifications$ | async as notifications">
            <!-- notification start -->
            <button
              mat-icon-button
              [matMenuTriggerFor]="builderNotifications"
              class="dropdown-toggle"
              matTooltip="Notifications"
            >
              <mat-icon aria-label="bell icon">
                <svg viewBox="0 0 24 24">
                  <path
                    d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21"
                  />
                </svg>
              </mat-icon>
              <span class="badge accent-background-color" *ngIf="notifications.length > 0 && notifications[0].total">{{
                notifications[0].total
              }}</span>
            </button>
            <mat-menu #builderNotifications="matMenu" class="builder-notifications fx-flex">
              <div mat-menu-item class="fx-flex fx-layout-row">
                <div class="fx-flex fx-layout-align-center center" *ngIf="notifications.length == 0">
                  You have no new notifications
                </div>
                <div class="fx-flex fx-layout-align-space-between-center" *ngIf="notifications.length > 0">
                  You have {{ notifications[0].total }} new notifications
                  <button mat-button class="" (click)="markAllRead()" title="Mark all as read">Clear all</button>
                </div>
              </div>
              <button
                mat-menu-item
                class="notifications fx-flex fx-layout-row"
                *ngFor="let notification of notifications"
              >
                <a
                  [routerLink]="notification.url"
                  [queryParams]="notification.urlQuery"
                  (click)="read(notification)"
                  class="fx-flex fx-layout-column fx-layout-align-start-start"
                >
                  {{ notification.body }}
                  <span class="notification-date">{{ notification.date | fromNow }}</span>
                </a>
                <button mat-icon-button (click)="read(notification)">
                  <mat-icon svgIcon="check"></mat-icon>
                </button>
              </button>
            </mat-menu>
          </ng-container>
        }

        <!-- Update button -->
        <button
          mat-icon-button
          class="update-button"
          (click)="showUpdateDialog()"
          *ngIf="updateAvailable$ | async"
          matTooltip="Update builder"
          aria-label="update builder"
        >
          <mat-icon aria-label="update icon">
            <svg viewBox="0 0 24 24">
              <path
                d="M21,10.12H14.22L16.96,7.3C14.23,4.6 9.81,4.5 7.08,7.2C4.35,9.91 4.35,14.28 7.08,17C9.81,19.7 14.23,19.7 16.96,17C18.32,15.65 19,14.08 19,12.1H21C21,14.08 20.12,16.65 18.36,18.39C14.85,21.87 9.15,21.87 5.64,18.39C2.14,14.92 2.11,9.28 5.62,5.81C9.13,2.34 14.76,2.34 18.27,5.81L21,3V10.12M12.5,8V12.25L16,14.33L15.28,15.54L11,13V8H12.5Z"
              />
            </svg>
          </mat-icon>
        </button>

        <!-- Time to overflow this -->
        <button
          class="builder-overflow-button"
          mat-icon-button
          [matMenuTriggerFor]="builderOverflowMenu"
          aria-label="Builder overflow menu"
        >
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
              />
            </svg>
          </mat-icon>
        </button>
        <mat-menu #builderOverflowMenu="matMenu">
          @if (isAuthenticated() && !isSwitchingApps()) {
            <!-- Upgrade subscription -->
            <a
              class="upgrade-subscription-button"
              mat-menu-item
              trackClick="Builder upgrade subscription menu button clicked"
              *ngIf="showUpgrade$ | async"
              routerLink="/upgrade"
            >
              <span class="upgrade-subscription-button-text">Upgrade subscription</span>
            </a>

            <!-- My Hub -->
            <a class="fx-hide-gt-xs" mat-menu-item [attr.href]="appUrl" target="_blank">
              <ng-container *ngTemplateOutlet="myHub"></ng-container>
            </a>
            <!-- Feedback -->
            <button mat-menu-item (click)="showFeedbackDialog($event)">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                  />
                </svg>
              </mat-icon>
              <span>Give feedback</span>
            </button>
            <!-- Help center -->
            <a mat-menu-item href="https://support.ihub.app/hc/en-us" target="_blank">
              <mat-icon>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
                  />
                </svg>
              </mat-icon>
              <span>Help center</span>
            </a>
          }
          <!-- Switch apps -->
          <a
            mat-menu-item
            *ngIf="isPartialLogin() || configSignal()?.impersonatorEmail || (canSwitchApps$ | async)"
            routerLink="/apps"
          >
            <mat-icon>
              <svg id="swap-horizontal-bold" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8,10V13H14V18H8V21L2,15.5L8,10M22,8.5L16,3V6H10V11H16V14L22,8.5Z" />
              </svg>
            </mat-icon>
            <span>Switch apps</span>
          </a>
          <!-- Logout -->
          <a mat-menu-item routerLink="/account/logout">
            <mat-icon>
              <svg id="logout-variant" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
                />
              </svg>
            </mat-icon>
            <span>Logout</span>
          </a>
        </mat-menu>
      </div>
    }
    <!--header end-->
    <main>
      <div id="pageTop"></div>
      <router-outlet></router-outlet>
      @if (isAuthenticated() && configSignal()) {
        <ih-content-body
          id="trackingCode"
          ihInterceptHref
          *ngIf="configSignal().settings"
          [html]="configSignal().settings.bridgeFooterCode"
        ></ih-content-body>
      }
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #myHub>
  <div class="fx-flex fx-layout-align-start-center">
    <mat-icon>
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          transform="rotate(180)"
          transform-origin="center"
          d="M17,1H7A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1M17,19H7V5H17V19M16,13H13V8H11V13H8L12,17L16,13Z"
        />
      </svg>
    </mat-icon>
    <span>My HUB</span>
  </div>
</ng-template>
