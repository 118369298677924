import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { SaveChangesDialogService } from '@ih/dialogs';
import { AuthGuard } from '@ih/services';
import { AppListGuardService } from './services/app-list-guard.service';
import { ImpersonateGuardService } from './services/impersonate-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'home', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard(), SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'view',
    loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule),
    canActivate: [AuthGuard(), SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'apps',
    loadComponent: () => import('./app-list/app-list.component').then((m) => m.AppListComponent),
    canActivate: [AuthGuard(true), AppListGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'impersonate',
    loadComponent: () => import('./impersonate/impersonate.component').then((m) => m.ImpersonateComponent),
    canActivate: [AuthGuard(true), ImpersonateGuardService, SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  {
    path: 'communicate',
    children: [
      {
        path: 'messages',
        loadChildren: () => import('@ih/messaging').then((m) => m.MessagesModule),
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: 'sliders',
        loadComponent: () => import('@ih/slider').then((m) => m.SliderListComponent),
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: 'pages',
        loadComponent: () => import('@ih/post').then((m) => m.PostListComponent),
        data: { title: 'Pages', singular: 'page', landingPage: true },
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: 'posts',
        loadComponent: () => import('@ih/post').then((m) => m.PostListComponent),
        data: { title: 'Posts', singular: 'post', landingPage: false },
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: 'events',
        loadComponent: () => import('@ih/content').then((m) => m.EventListComponent),
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: 'submittedContent',
        loadComponent: () => import('@ih/content').then((m) => m.SubmittedContentComponent),
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      },
      {
        path: '',
        loadComponent: () => import('@ih/content').then((m) => m.AllContentComponent),
        canActivate: [SaveChangesDialogService],
        canDeactivate: [SaveChangesDialogService]
      }
    ],
    canActivate: [AuthGuard()]
  },
  {
    path: 'manage',
    loadChildren: () => import('./manage/manage.module').then((m) => m.ManageModule),
    canActivate: [AuthGuard()]
  },
  {
    path: 'upgrade',
    loadChildren: () => import('@ih/upgrade').then((m) => m.UpgradeModule),
    canActivate: [AuthGuard()]
  },
  {
    // enableTracing: !environment.production,
    matcher: isAngularJSUrl,
    loadChildren: () => import('./angularjs/angularjs.module').then((m) => m.AngularjsModule),
    canActivate: [AuthGuard(), SaveChangesDialogService],
    canDeactivate: [SaveChangesDialogService]
  },
  { path: '**', redirectTo: '/dashboard' }
];

export function isAngularJSUrl(url: UrlSegment[]): { consumed: UrlSegment[] } {
  return url.length > 0 &&
    url[0].path !== '/' &&
    !url[0].path.startsWith('dashboard') &&
    !(url.length === 2 && url[0].path === 'view' && url[1].path === 'payments') &&
    !(url.length === 2 && url[0].path === 'view' && url[1].path === 'subscriptions') &&
    !url[0].path.startsWith('apps') &&
    !url[0].path.startsWith('impersonate') &&
    !(
      url.length > 3 &&
      url[0].path === 'manage' &&
      url[1].path === 'channels' &&
      url[2].path != null &&
      url[3].path === 'overlay'
    ) &&
    !(url.length > 1 && url[0].path === 'account' && url[1].path === 'logout')
    ? { consumed: url }
    : null;
}

@NgModule({
  providers: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
