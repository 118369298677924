import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MetadataService } from '@ih/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppListGuardService {
  constructor(
    private router: Router,
    private metadata: MetadataService
  ) {}

  canActivate(): Observable<boolean> {
    return this.metadata.canSwitchApps$.pipe(
      tap((canSwitchApps) => {
        if (!canSwitchApps) {
          this.router.navigate(['/']);
        }
      })
    );
  }
}
