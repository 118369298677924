import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { ImageInfo } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type ImpersonationConfirmationDialogComponent } from './impersonation-confirmation-dialog.component';

export interface ImpersonationConfirmationOptions {
  name: string;
  email: string;
  appName: string;
  profileImage: ImageInfo;
}

@Injectable({
  providedIn: 'root'
})
export class ImpersonationConfirmationDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  async open(
    options: ImpersonationConfirmationOptions
  ): Promise<MatDialogRef<ImpersonationConfirmationDialogComponent>> {
    const impersonationConfirmationDialogComponent: ComponentType<ImpersonationConfirmationDialogComponent> =
      await import('./impersonation-confirmation-dialog.component').then(
        (m) => m.ImpersonationConfirmationDialogComponent
      );
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(impersonationConfirmationDialogComponent, {
      data: options,
      panelClass: ['impersonation-confirmation-dialog', 'basic-dialog']
    });
  }
}
