import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport
} from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  AddWebsiteDialogComponent,
  ChangeBackgroundImageDialogComponent,
  ChangePasswordDialogComponent,
  ChangeProfileImageDialogComponent,
  ProfileEditorComponent
} from '@ih/account';
import { ChangeIconDialogComponent, ChangeLogoDialogComponent } from '@ih/admin';
import { ChannelPickerComponent } from '@ih/channel-picker';
import { ConfirmDialogComponent } from '@ih/confirm';
import { ENVIRONMENT_PRODUCT } from '@ih/constants';
import { ContentBodyComponent } from '@ih/content';
import { DateTimePickerComponent } from '@ih/date-time-picker';
import { SaveChangesDialogComponent } from '@ih/dialogs';
import { EmojiPipe } from '@ih/emoji';
import { Products } from '@ih/enums';
import { FroalaComponent } from '@ih/froala';
import { InfoPanelComponent } from '@ih/info-panel';
import { BuildConfig, CampaignSummary } from '@ih/interfaces';
import { LbToBrPipe, ToSrcSetPipe } from '@ih/pipes';
import {
  AddModuleDialogComponent,
  AuthorPickerComponent,
  PostFromLinkDialogComponent,
  PostHistoryDialogComponent,
  TaskVolunteerDetailDialogComponent
} from '@ih/post-editor';
import { SafePipe } from '@ih/safe-pipe';
import { ConfirmPasswordDialogComponent } from '@ih/security';
import {
  AuthService,
  CheckForUpdateService,
  ConfigService,
  ErrorHandlerService,
  FirebaseModule,
  MatomoService,
  MetadataService,
  MonitoringService,
  SecurityService,
  SignalRService,
  StorageModule
} from '@ih/services';
import { BaseUrlInterceptor } from '@ih/shared';
import { FromNowPipe } from '@ih/time';
import { UnfurlPreviewComponent } from '@ih/unfurl-preview';
import { IconRegistryService } from 'libs/services/src/lib/icon-registry.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { firstValueFrom, from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImpersonationConfirmationDialogService } from './impersonation-confirmation-dialog/impersonation-confirmation-dialog.service';
import { ImpersonateGuardService } from './services/impersonate-guard.service';

@NgModule({
  declarations: [],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    GoogleMapsModule,

    DragDropModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollingModule,

    AddModuleDialogComponent,
    AddWebsiteDialogComponent,
    AuthorPickerComponent,
    ChangeBackgroundImageDialogComponent,
    ChangeIconDialogComponent,
    ChangeLogoDialogComponent,
    ChangePasswordDialogComponent,
    ChangeProfileImageDialogComponent,
    ChannelPickerComponent,
    ConfirmDialogComponent,
    ConfirmPasswordDialogComponent,
    ContentBodyComponent,
    DateTimePickerComponent,
    EmojiPipe,
    FroalaComponent,
    FromNowPipe,
    InfoPanelComponent,
    LbToBrPipe,
    PostFromLinkDialogComponent,
    PostHistoryDialogComponent,
    ProfileEditorComponent,

    SafePipe,

    SaveChangesDialogComponent,
    TaskVolunteerDetailDialogComponent,
    ToSrcSetPipe,
    UnfurlPreviewComponent,

    AppComponent,
    AppRoutingModule,

    ServiceWorkerModule.register((environment.production ? '/en' : '') + '/ngsw-worker.js', { enabled: true }),
    StorageModule.forRoot({
      idbName: 'build',
      idbStoreName: 'data',
      idbSchemaVersion: 2
    }),
    FirebaseModule.forRoot({
      apiKey: 'AIzaSyCHhrP8cbemEhpGMKE2L8Uigl6PDVde4oE',
      authDomain: 'quantum-gearbox-557.firebaseapp.com',
      databaseURL: 'https://quantum-gearbox-557.firebaseio.com',
      projectId: 'quantum-gearbox-557',
      storageBucket: 'quantum-gearbox-557.appspot.com',
      messagingSenderId: '261205718171',
      appId: '1:261205718171:web:3a57afd49efd33e2e8423e',
      vapid: 'BI5DtqI3h8Db7NQGHawXFQ1TEpKN8GPizj_QN0U2b_WzhNY39ctC4L_Q4z1u6k6sC709W2hYcDZpt5j1UnB37-g',
      apiUrl: '/api/account/pushNotification/fcm',
      measurementId: 'G-3EGFRZW3WQ'
    }),

    InfiniteScrollModule
  ],
  providers: [
    ImpersonateGuardService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [
        IconRegistryService,
        ConfigService,
        ImpersonationConfirmationDialogService,
        HttpClient,
        Meta,
        MetadataService,
        SecurityService,
        MonitoringService,
        SignalRService,
        AuthService,
        Router
      ]
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: ENVIRONMENT_PRODUCT, useValue: Products.Builder },
    CheckForUpdateService,
    MetadataService,
    MatomoService,
    MonitoringService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
})
export class AppModule implements DoBootstrap {
  constructor(_signalr: SignalRService, _config: ConfigService<BuildConfig>, _checkForUpdate: CheckForUpdateService) {
    document.version = window.version;
    console.info('InspireHUB Builder - Running version: ' + window.version);

    _checkForUpdate.init();

    _signalr.forceUpdateCheck$.subscribe(() => {
      _config.syncConfig().subscribe();
    });
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(AppComponent);
  }
}

export function initApp(
  iconRegistry: IconRegistryService,
  config: ConfigService<BuildConfig>,
  impersonationConfirmation: ImpersonationConfirmationDialogService,
  httpClient: HttpClient,
  meta: Meta,
  metadata: MetadataService,
  security: SecurityService,
  monitoring: MonitoringService,
  signalr: SignalRService,
  auth: AuthService,
  router: Router
) {
  iconRegistry.registerIcons();

  return () => {
    config.registerConfigTransformer((config: BuildConfig) => {
      window.campaign = config;

      return from(auth.syncUser()).pipe(
        switchMap((currentUser) =>
          !currentUser
            ? of(config)
            : httpClient.get<CampaignSummary>('/api/campaigns/mine/summary').pipe(
                switchMap((summary) => {
                  metadata.init();

                  if (currentUser) {
                    config.currentUser = {
                      ...config.currentUser,
                      ...currentUser
                    };
                  }

                  return of({ ...config, ...summary });
                })
              )
        ),
        tap((buildConfig: BuildConfig) => {
          // only run this if the user login is complete
          // this can be null during impersonation
          if (buildConfig.currentUser) {
            security.sync(
              buildConfig.currentUser.roleSecurity.permissions,
              buildConfig.currentUser.channelRoleSecurities
            );

            // update meta tags
            meta.updateTag({ content: buildConfig.businessName }, "property='description'");

            meta.updateTag({ content: buildConfig.businessName }, "property='og:site_name'");
            meta.updateTag({ content: buildConfig.businessName }, "property='og:title'");
            meta.updateTag({ content: buildConfig.businessName }, "property='og:description'");
            meta.updateTag({ content: `https://${window.location.host}/home` }, "property='og:url'");
            meta.updateTag({ content: buildConfig.style?.logoUrls?.[0].url }, "property='og:image'");

            meta.updateTag({ content: 'summary_large_image' }, "property='twitter:card'");
            meta.updateTag({ content: buildConfig.businessName }, "property='twitter:title'");
            meta.updateTag({ content: buildConfig.style?.logoUrls?.[0].url }, "property='twitter:image'");

            meta.updateTag({ content: buildConfig.style?.primaryColor }, "name='theme-color'");

            // don't add the icons if they already exist
            if (!document.querySelector("link[rel='apple-touch-icon'")) {
              const frag = document.createDocumentFragment();
              buildConfig.settings.icons.forEach((icon) => {
                const link = window.document.createElement('link');
                link.setAttribute('rel', icon.platformId === 2 ? 'apple-touch-icon' : 'icon');
                link.setAttribute('sizes', `${icon.width}x${icon.height}`);
                link.setAttribute('href', icon.cdnUrl);
                frag.append(link);
              });
              window.document.head.append(frag);
            }
          }
        })
      );
    });

    config
      .syncConfig()
      .pipe(
        catchError((err) => {
          // if we're not on /apps or /impersonate, redirect to login
          if (window.location.pathname !== '/apps' && window.location.pathname !== '/impersonate') {
            console.debug('Redirecting to login because something went wrong', err);
            router.navigateByUrl('/account/login');
          }

          throw err;
        })
      )
      .subscribe();

    return firstValueFrom(config.config$).then((buildConfig: BuildConfig) => {
      // sanity checks
      // if the user has not selected a campaign or user
      if (buildConfig.campaignId === 0 || buildConfig.currentUser == null) {
        // if we're not on /apps or /impersonate, redirect to login
        if (window.location.pathname !== '/apps' && window.location.pathname !== '/impersonate') {
          console.debug('Redirecting to login because the member has not selected a campaign and tried to go home');
          router.navigateByUrl('/account/login');
        }

        // don't do anything else
        return;
      }

      signalr.connect();
      // TODO: Remove this once we've elmiminated the window.campaign in the builder entirely
      window.campaign = buildConfig;
      window.cdnUrl = buildConfig.cdnUrl;

      monitoring.init(buildConfig.keys.aiKey, buildConfig.environment.version).subscribe();

      if (buildConfig.currentUser) {
        monitoring.setUserId(buildConfig.currentUser.campaign_user_id.toString(), buildConfig.slug);

        if (
          buildConfig.impersonatorEmail &&
          window.location.pathname !== '/apps' &&
          window.location.pathname !== '/impersonate'
        ) {
          impersonationConfirmation.open({
            appName: buildConfig.businessName,
            name: buildConfig.currentUser.fullName,
            email: buildConfig.currentUser.email,
            profileImage: {
              url: buildConfig.currentUser.profileImage.url,
              blurHash: buildConfig.currentUser.profileImage.blurHash,
              color: buildConfig.currentUser.profileImage.color,
              cropData: buildConfig.currentUser.profileImage.cropData,
              stockPhoto: buildConfig.currentUser.profileImage.stockPhoto
            }
          });
        }
      }
    });
  };
}
