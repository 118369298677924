import { AsyncPipe, NgClass, NgComponentOutlet, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, ViewChild, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterEvent, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { FeedbackDialogService } from '@ih/admin';
import { ConfirmDialogService } from '@ih/confirm';
import { FREE_PRODUCT_IDS } from '@ih/constants';
import { ContentBodyComponent } from '@ih/content';
import { DeviceInfoDialogService } from '@ih/dialogs';
import { TrackClickDirective } from '@ih/directives';
import { ImageComponent } from '@ih/image';
import { BuildAccount, BuildConfig, CampaignSummary, CommentSummary, ImageInfo } from '@ih/interfaces';
import {
  AppThemeService,
  AuthService,
  ChannelService,
  ConfigService,
  LayoutService,
  LazyInject,
  MetadataService,
  RightSidenavService
} from '@ih/services';
import { FromNowPipe } from '@ih/time';
import { getAppOriginFromConfig } from '@ih/utilities';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
@Component({
  standalone: true,
  selector: 'ih-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    AsyncPipe,
    NgClass,
    NgComponentOutlet,
    NgFor,
    NgIf,
    NgTemplateOutlet,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,

    ContentBodyComponent,
    FromNowPipe,
    ImageComponent,
    TrackClickDirective
  ]
})
export class AppComponent implements OnDestroy {
  @ViewChild('sideNav') sideNav: MatSidenav;

  @ViewChild('rightSidenav') rightSidenav: MatSidenav;

  private loginUrls = ['/apps', '/impersonate'];
  private lastAuthenticatedValue: boolean = null;

  private config = inject(ConfigService<BuildConfig>) as ConfigService<BuildConfig>;

  private metadata = inject(MetadataService);
  private router = inject(Router);
  private updates = inject(SwUpdate);
  private confirmDialog = inject(ConfirmDialogService);
  private feedbackDialog = inject(FeedbackDialogService);
  private auth = inject(AuthService);
  private http = inject(HttpClient);
  private appTheme = inject(AppThemeService);
  private deviceInfo = inject(DeviceInfoDialogService);
  private layout = inject(LayoutService);
  private rightSidenavService = inject(RightSidenavService);
  private lazyInject = inject(LazyInject);
  private channel = inject(ChannelService);

  isAuthenticated = toSignal(this.auth.isAuthenticated$);

  isPartialLogin = toSignal(this.auth.partialLogin$);

  version = toSignal(
    this.config.config$.pipe(
      map((config) =>
        config.environment.environment === 'Development'
          ? config.environment.branch
          : `${config.environment.version} [${config.environment.region}]`
      )
    )
  );

  matomoEnabled = toSignal(
    this.config.config$.pipe(
      map((config) => config.enableMatomo && config.currentUser?.roleSecurity.permissions.ViewAnalytics)
    )
  );

  // if there is no plan type specified then fall back to free
  showUpgrade$ = combineLatest([this.config.config$, this.auth.currentUser$]).pipe(
    map(
      ([config, currentUser]) =>
        (currentUser?.isOwner || currentUser?.isAdmin) &&
        FREE_PRODUCT_IDS.indexOf(config.plan?.productId || 'free') !== -1
    )
  );

  billingUrlLoading$ = new BehaviorSubject<boolean>(false);

  paidPlan$ = this.config.config$.pipe(map((appConfig) => FREE_PRODUCT_IDS.indexOf(appConfig.plan.productId) === -1));

  currentUser$ = this.auth.currentUser$.pipe(map((user) => user as unknown as BuildAccount));

  totalMessageCount$: Observable<number>;
  notifications$: Observable<any>;
  scrollDisabled: boolean;
  showConfigure = false;
  commentSummary$: Observable<CommentSummary>;
  campaignSummary$: Observable<CampaignSummary>;
  canSwitchApps$: Observable<boolean>;
  shouldBeOver$ = this.layout.shouldBeOver$;

  rightDrawerComponent$ = this.rightSidenavService.componentType$.pipe(tap((cmp) => console.log(cmp)));

  profileImage$: Observable<ImageInfo> = this.auth.currentUser$.pipe(
    map((currentUser) => {
      if (currentUser?.profileImage) {
        return {
          url: currentUser.profileImage.url,
          blurHash: currentUser.profileImage.blurHash,
          color: currentUser.profileImage.color,
          cropData: currentUser.profileImage.cropData,
          stockPhoto: currentUser.profileImage.stockPhoto
        } as ImageInfo;
      }

      return null;
    })
  );

  showCustomDomains$ = this.config.config$.pipe(
    map(
      (buildConfig) =>
        buildConfig.currentUser.roleSecurity.permissions.ManageAppSettings && buildConfig.features?.customDomains
    )
  );

  menuOptions$ = this.config.config$.pipe(
    map((buildConfig) => {
      if (!buildConfig.menuOptions) return;

      // prevent mangling the current config value
      const menuOptions = buildConfig.menuOptions;
      const currentUser = buildConfig.currentUser;

      // if the user it not logged in disable the admin menu
      if (!currentUser) {
        Object.keys(menuOptions).forEach((key) => {
          menuOptions[key] = false;
        });

        return menuOptions;
      }

      // apply user permissons
      // sliders are enabled if the option is turned on and the user can publish to any channel
      menuOptions.slidersEnabled &&= currentUser.channelRoleSecurities.some(
        (c) => c.permissions.PostsPublishScheduleArchive
      );

      // pinned content is enabled if the option is turned on and the user can publish to any channel
      menuOptions.pinnedContentEnabled &&= currentUser.channelRoleSecurities.some(
        (c) => c.permissions.PostsPublishScheduleArchive
      );

      // members are enabled if the option is turned on and user has invite permission
      menuOptions.membersEnabled &&= currentUser.roleSecurity.permissions.UsersInviteEdit;

      // channels are enabled if the option is turned on and user can edit a channel, invite users to a channel
      // edit roles, or edit the lock screen
      menuOptions.channelsEnabled &&= currentUser.channelRoleSecurities.some(
        (c) =>
          c.permissions.ChannelEdit ||
          c.permissions.ChannelInviteEditUsers ||
          c.permissions.ChannelRoleCreateEdit ||
          c.permissions.LockChannel
      );

      menuOptions.lookAndFeelEnabled &&= currentUser.roleSecurity.permissions.ManageAppSettings;
      menuOptions.advancedOptionsEnabled &&= currentUser.roleSecurity.permissions.ManageAppSettings;
      menuOptions.customCodeEnabled &&= currentUser.roleSecurity.permissions.ManageAppSettings;
      menuOptions.homeCustomizationEnabled &&= currentUser.roleSecurity.permissions.ManageAppSettings;
      menuOptions.welcomeMessageEnabled &&= currentUser.roleSecurity.permissions.ManageAppSettings;
      menuOptions.menuEditEnabled &&= currentUser.roleSecurity.permissions.ManageAppSettings;

      return menuOptions;
    })
  );

  destroy$ = new Subject<void>();

  appUrl: string;

  config$ = this.config.config$.pipe(
    tap((buildConfig) => {
      if (!buildConfig.style) return;

      this.appUrl = getAppOriginFromConfig(buildConfig);

      this.appTheme.setCssVariables({
        primaryColor: buildConfig.style.primaryColor,
        primaryColorFont: buildConfig.style.primaryColorFont,
        accentColor: buildConfig.style.accentColor,
        accentColorFont: buildConfig.style.accentColorFont,
        navTextColor: buildConfig.style.navTextColor
      });

      this.showConfigure =
        buildConfig.currentUser.roleSecurity.permissions.ManageAppSettings ||
        (buildConfig.currentUser.roleSecurity.permissions.ManageBilling && buildConfig.billingUrl != null) ||
        buildConfig.currentUser.roleSecurity.permissions.ManageCompanyProfile ||
        buildConfig.currentUser.roleSecurity.permissions.ManageContact ||
        buildConfig.currentUser.roleSecurity.permissions.ManageIntegrations ||
        buildConfig.currentUser.roleSecurity.permissions.ManageMerchantAccounts;
    })
  );

  configSignal = toSignal(this.config$);

  paidChannelsEnabled = toSignal(this.config.config$.pipe(map((config) => config.features?.paidChannels)));

  channelCanPublish$ = this.config.config$.pipe(
    map((buildConfig) => {
      if (!buildConfig.currentUser) {
        return false;
      }

      return buildConfig.currentUser.channelRoleSecurities.some((c) => c.permissions.PostsPublishScheduleArchive);
    })
  );

  channelCanModerate$ = this.config.config$.pipe(
    map((buildConfig) => {
      if (!buildConfig.currentUser) {
        return false;
      }

      return buildConfig.currentUser.channelRoleSecurities.some((c) => c.permissions.ModerateComments);
    })
  );

  updateAvailable$ = this.updates.versionUpdates.pipe(
    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
  );

  isSwitchingApps = signal(false);

  constructor() {
    const url = window.location.pathname;
    this.updateAuthenticated(!this.loginUrls.some((u) => url.startsWith(u)));

    this.router.events
      .pipe(withLatestFrom(this.shouldBeOver$, this.config.config$))
      .subscribe(([event, shouldBeOver, buildConfig]) => {
        const routerEvent = event as RouterEvent;

        if (routerEvent instanceof NavigationEnd) {
          const loader = document.getElementById('loader');
          if (loader) {
            loader.remove();
            // clear loader timers
            clearInterval(window.messageInterval);
          }

          // if we're on /apps then we're switching apps
          this.isSwitchingApps.set(routerEvent.url && routerEvent.url.startsWith('/apps'));
        }

        if (routerEvent.url && this.router.navigated && routerEvent instanceof NavigationEnd) {
          this.updateAuthenticated(!this.loginUrls.some((u) => routerEvent.url.startsWith(u)));

          if (shouldBeOver) {
            this.sideNav?.close();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateAuthenticated(authenticated: boolean) {
    if (this.lastAuthenticatedValue === authenticated) return;
    this.lastAuthenticatedValue = authenticated;

    if (authenticated) {
      this.totalMessageCount$ = this.metadata.totalMessageCount$;
      this.notifications$ = this.metadata.notifications$;
      this.commentSummary$ = this.metadata.commentSummary$;
      this.campaignSummary$ = this.metadata.campaignSummary$;
      this.canSwitchApps$ = this.metadata.canSwitchApps$;

      this.metadata.init();
    } else {
      this.sideNav?.close();
    }
  }

  toggleParent(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    Array.from(document.querySelectorAll('.sidenav-item.sub-menu')).forEach((el) => {
      if (el !== (e.target as HTMLAnchorElement).parentElement && !el.contains(e.target as HTMLAnchorElement)) {
        el.classList.remove('open');
      } else {
        el.classList.contains('open') ? el.classList.remove('open') : el.classList.add('open');
      }
    });
  }

  markAllRead(): void {
    this.http.put<any>('/api/notifications/read', {}).subscribe(() => {
      this.metadata.updateNotification();
    });
  }

  read(notification): void {
    this.http.put<any>('/api/notifications/' + notification.notificationId + '/read', {}).subscribe(() => {
      this.metadata.updateNotification();
    });
  }

  async showUpdateDialog(event?: Event): Promise<void> {
    if (event) {
      event.preventDefault();
    }

    const dialog = await this.confirmDialog.open({
      title: 'Update now?',
      message: 'This will refresh your app to apply any new updates that are available.',
      confirmText: 'UPDATE'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  showDeviceInfo(): void {
    this.deviceInfo.open();
  }

  showFeedbackDialog(event: Event): void {
    event.preventDefault();

    this.feedbackDialog.open();
  }

  async showHomeCustomization(): Promise<Promise<void>> {
    const channelCustomizeDialogService = await this.lazyInject.get(() =>
      import('@ih/channel').then((m) => m.ChannelCustomizeDialogService)
    );
    const homeChannel = await firstValueFrom(this.channel.homeChannel$);
    channelCustomizeDialogService.open(homeChannel.channelId);
  }

  navigate(url: string, queryParams?: any): void {
    this.router.navigate([url], {
      queryParams
    });
  }
}
